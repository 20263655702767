import * as React from "react"

import { H1, H2, H3, Paragraph, Title } from "../components/Typography"
import { graphql, navigate } from "gatsby"

import AppLayout from "../components/AppLayout"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Wrapper = styled.section`
  margin: 50px 0 100px 0;
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5vh;
`

const Homepage: React.FC = () => {
  const { i18n } = useTranslation()

  React.useEffect(() => {
    if (i18n.language === "cs") {
      navigate("/ochrana-soukromi")
    }
  }, [i18n.language])

  return (
    <AppLayout>
      <Sections>
        <Wrapper>
          <Paragraph>
            <Title>Information on the processing of personal data</Title>
            <H2>Overview</H2>
            <p>
              The company VAFO PRAHA, s.r.o., processes your personal data, as
              it is essential to the performance of the contractual relationship
              arising from your participation in the loyalty program. If on your
              loyalty case you granted consent to the processing of your
              personal data for purposes of receiving marketing messages
              (special offers), your personal data will also be processed for
              this purpose. You may revoke this consent at any time, after which
              you will no longer receive any marketing messages. Your personal
              data will be collected by a retail store operator on the basis of
              a personal data processing agreement between VAFO PRAHA, s.r.o.,
              and the processor (hereafter the agreement).
            </p>
            <H3>1. Administrator identity and contact information</H3>
            <p>
              1. The administrator of your personal data is the company VAFO
              PRAHA, s.r.o., with place of business at K Brůdku 94, 252 19
              Chr&aacute;&scaron;ťany, ID: 614 99 587, entered in the commercial
              register of the Prague Municipal Court, section C, insert 30564
              (hereafter the administrator).
            </p>
            <p>
              2. The administrator&rsquo;s contact information is as follows:
              mailing address: K Brůdku 94, 25219 Chr&aacute;&scaron;ťany; email
              address: osobniudaje@vafo.cz; tel. 257952141.
            </p>
            <p>
              3. The administrator has not appointed a representative for the
              protection of personal data.
            </p>
            <H3>2. Legal basis for processing personal data</H3>
            <p>
              1. The legal basis for processing your personal data is the fact
              that such processing is essential for the performance of the
              agreement between you and the administrator or for the
              administrator to implement suitable measures prior to concluding
              such an agreement in compliance with article 6, paragraph 1,
              letter b) of Regulation (EU) 2016/679 of the European Parliament
              and of the Council on the protection of natural persons with
              regard to the processing of personal data and on the free movement
              of such data (hereafter the regulation), and repealing Directive
              95/46/EC (General Data Protection Regulation).
            </p>
            <p>
              2. If on your loyalty case you granted consent to the processing
              of your personal data for purposes of receiving marketing messages
              (special offers), the legal basis for processing your personal
              data is the consent you granted to the administrator in accordance
              with article 6, paragraph1, letter a) of the regulation.
            </p>
            <H3>3. Purpose of personal data processing</H3>
            <p>
              1. The purpose of processing your personal data is the performance
              of the agreement between you and the administrator pertaining to
              your participation in the loyalty program.
            </p>
            <p>
              2. If on your loyalty case you granted consent to the processing
              of your personal data for purposes of receiving marketing messages
              (special offers), the purpose of processing your personal data is
              the distribution of marketing messages to you by the
              administrator.
            </p>
            <p>
              3. In compliance with article 22 of the regulation, the
              administrator shall not engage in any automated decision-making.
            </p>
            <H3>4. Personal data retention period</H3>
            <p>
              1. Your personal data will be processed for the duration of the
              agreement and the obligations and rights stemming from it and then
              for the period required for purposes of archiving in compliance
              with generally binding laws and regulations but not exceeding the
              period stipulated by generally binding laws and regulations.
            </p>
            <p>
              2. The retention period for your personal data processed by the
              administrator for purposes of sending you marketing messages
              (article 3.2) is four (4) years from the date on which you granted
              consent to such processing or sooner if you revoke your consent to
              the processing of your personal data for this purpose prior to the
              end of the four-year period.
            </p>
            <H3>5. Other personal data recipients</H3>
            <p>
              1. Other recipients of your personal data are persons providing
              the administrator with technical services relating to the
              management of the loyalty program, including the administration of
              software and data storage, and operators of stores participating
              in the loyalty program as partners (personal data processors).
            </p>
            <p>
              2. Other recipients of your personal data processed by the
              administrator for purposes of sending you marketing messages
              (article 3.2) will be persons who, together with the
              administrator, comprise the concern as well as those providing the
              administrator with marketing services in accordance with the
              administrator&rsquo;s instructions (personal data processors).
            </p>
            <p>
              3. The administrator has no intention of transferring your
              personal data to a third country (i.e. to a country outside the
              EU) or to any international organization with the exception of
              transferring your personal data for purposes of sending you
              marketing messages (article 3.2) to the United States to the
              company SendGrid, Inc., with place of business at 1801 California
              Street, Suite 500 Denver, Colorado 80202. The company SendGrid,
              Inc., is a certified entity within the EU-US Privacy Shield in
              accordance with European Commission Implementing Decision (EU)
              2016/1250 of 12 July 2016 pursuant to Directive 95/46/EC of the
              European Parliament and of the Council on the adequacy of the
              protection provided by the EU-US Privacy Shield.
            </p>
            <H3>
              6. Rights of data subjects in the case of processing relating to
              participation in the loyalty program
            </H3>
            <p>
              1. In accordance with the conditions of the regulation, you have
              the right to request access to your personal data from the
              administrator as well as the right to rectification or erasure of
              your personal data and/or restriction of its processing. You also
              have the right to portability of your personal data.
            </p>
            <p>
              2. If you believe that the processing of your personal data has
              resulted in a violation of the regulation or continues to violate
              the regulation, you have the right to file a complaint with the
              Office for Personal Data Protection.
            </p>
            <p>
              3. You are not obligated to provide your personal data; however,
              the provision of your personal data is a requirement for the
              conclusion and performance of the agreement (participation in the
              loyalty program), and without your personal data the agreement
              cannot be concluded or fulfilled on the part of the administrator.
            </p>
            <H3>
              7. Rights of data subjects in the case of processing for purposes
              of sending marketing messages
            </H3>
            <p>
              1. In the case of personal data processed by the administrator for
              purposes of sending you marketing messages (article 3.2):
            </p>
            <p>
              1. you are not obligated to provide your personal data; the
              provision of personal data is not a legal or contractual
              requirement and is not required for the conclusion of the
              agreement;
            </p>
            <p>
              2. you have the right to revoke your consent to the processing of
              your personal data by the administrator at any time; however,
              doing so will have no effect on the lawfulness of processing your
              personal data prior to such a revocation of your consent; you can
              revoke your consent to the processing of your personal data by
              clicking on the hyperlink in every marketing message sent to you
              by the administrator or by sending an email to the administrator
              at osobniudaje@vafo.cz;
            </p>
            <p>
              3. you have the right to raise an objection at any time to the
              processing of your personal data for purposes of direct marketing,
              including profiling if profiling is part of such direct marketing.
              If you raise an objection to the processing of your personal data
              for purposes of direct marketing, your personal data will no
              longer be processed for these purposes.
            </p>
          </Paragraph>
        </Wrapper>
      </Sections>
    </AppLayout>
  )
}

export default Homepage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
